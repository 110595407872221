<template>
  <div class="flex w-full justify-center items-center min-h-screen bg-gray-50">
    <div class="w-1/4 flex flex-col bg-white shadow p-6">
      <div class="mx-auto text-center text-2xl font-bold">
        <img src="@/assets/logo.svg" :alt="appName" />
      </div>

      <h1 class="text-center font-medium text-3xl">Passwort zurücksetzen</h1>

      <h3 class="text-xs font-medium mt-4 mb-2">
        Bitte füllen Sie das Formular aus, um das Passwort zurückzusetzen
      </h3>
      <h3 class="text-xs mb-4">
        <ul class="space-y-2">
          <li>
            1. Tragen Sie unten Ihre E-Mail Adresse ein und klicken Sie auf "Passwort zurücksetzen".
          </li>
          <li>
            2. Öffnen Sie die Bestätigungsemail und klicken Sie auf den Link um Ihr Passwort
            zurückzusetzen.
          </li>
        </ul>
      </h3>

      <h3 v-if="error" class="text-center text-xs my-4 text-red-500" v-html="error"></h3>

      <div class="flex flex-col mt-4 text-sm">
        <div class="my-2">
          <input
            type="password"
            v-model="$v.password.$model"
            placeholder="neues Passwort, mindestens 6 Zeichen."
            class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
          />
        </div>

        <div class="my-2">
          <input
            type="password"
            v-model="$v.confirm_password.$model"
            placeholder="Passwort bestätigen"
            class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
          />
        </div>

        <div class="my-2">
          <button
            class="rounded bg-blue-500 w-full text-center py-2 text-white"
            @click.prevent="updatePasswordAndLogin"
          >
            Passwort zurücksetzen
          </button>
        </div>

        <div class="mt-4">
          <router-link to="login" class="text-blue-400 text-center block">Abbrechen</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePassword } from '@/services/auth'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'update-password',
  props: ['value'],
  components: {},
  title: 'Update Password',
  data() {
    return {
      password: null,
      confirm_password: null,
      error: false,
    }
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        sameAsPassword: sameAs('password'),
      },
    }
  },
  methods: {
    updatePasswordAndLogin() {
      const uidb64 = this.$route.query.uidb64
      const token = this.$route.query.token
      const client = this.$route.query.client

      if (!this.$v.$invalid) {
        updatePassword(this.password, uidb64, token, client)
          .then(() => {
            this.$router.push('/login/?msg=Passwort erfolgreich aktualisiert')
          })
          .catch(err => {
            console.log('Err ::: ', err)
          })
      } else if (this.password.length < 6) {
        this.error =
          'Passwort ist zu kurz, bitte geben Sie ein mindestens 6 Zeichen langes Passwort an.'
      } else {
        this.error = 'Passwort nicht übereinstimmen,<br /> geben Sie das Passwort bitte erneut ein.'
      }
    },
  },
}
</script>

<style></style>
